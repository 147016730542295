import React, { useEffect, useState } from "react";
import axios from "axios";
import API from "../../../api/Api";
import { useAuth } from "../../../context/authContext/Auth";
import HubsReportCard from "../../HubsComponents/HubsReportCard";
import Loader from "../../Common/Loader";
import SearchIcon from "../../../assets/search.svg";
import {
  CompanyReportsContainer,
  ReportsHeader,
  ReportsList,
  Pagination,
} from "./CompanyHome.styles";

function CompanyReports(props) {
  const [loading, setLoading] = useState(false);
  const [currentTab, setCurrentTab] = useState("all-reports");
  const [reportsPerPage, setReportsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [myReports, setMyReports] = useState(0);
  const [allReports, setAllReports] = useState(0);
  const [reports, setReports] = useState([]);
  const [filteredReports, setFilteredReports] = useState([]);
  const [currentPageReports, setCurrentPageReports] = useState([]);

  const { config } = useAuth();
  const { Base_URL } = API;
  const paginationArr = [5, 10, 20, 30, 40, 50];
  let debounceTimer;

  const activeButtonStyle = {
    background: "rgba(68, 107, 166, 1)",
    color: "#fff",
    height: "44px",
    display: "inline-block",
    padding: "8px, 24px, 8px, 24px",
    borderRadius: "8px",
    margin: "16px 16px 24px 0",
    textTransform: "capitalize",
    fontSize: "16px",
    fontWeight: "500",
    border: "none",
    outline: "none",
    padding: "10px 20px",
  };

  const buttonStyle = {
    color: "rgba(90, 90, 90, 1)",
    textTransform: "capitalize",
    display: "inline-block",
    padding: "8px, 24px, 8px, 24px",
    margin: "16px 16px 24px 0",
    fontSize: "16px",
    fontWeight: "500",
    border: "none",
    outline: "none",
    padding: "10px 20px",
    background: "none",
    borderRadius: "8px",
  };

  useEffect(() => {
    getReportsList();
  }, [props.company?.hubId, props.company?.companyId]);

  useEffect(() => {
    let newData;
    if (currentTab === "my-reports") {
      newData = [...reports].filter((report) => report.subscribed === "yes");
    } else if (currentTab === "all-reports") {
      newData = [...reports];
    }

    let newFilteredReports;

    if (props?.selectedRegion === "") {
      newFilteredReports = [...newData];
    } else {
      newFilteredReports = newData.filter((report) => {
        if (
          props?.selectedRegion === "All" ||
          props?.selectedRegion === "all"
        ) {
          return true;
        }
        return report.dimensions.region === props?.selectedRegion?.trim();
      });
    }

    let OBJ = props.selectedDimensions;

    for (let key in OBJ) {
      newFilteredReports = newFilteredReports.filter((report) => {
        if (OBJ[key] === "All" || OBJ[key] === "all") {
          return true;
        } else if (report.dimensions[key] === OBJ[key]) {
          return true;
        } else {
          return false;
        }
      });
    }

    setCurrentPageReports(
      newFilteredReports.slice(
        0,
        newFilteredReports.length >= 10 ? 10 : newFilteredReports.length
      )
    );
    setFilteredReports(newFilteredReports);
    setTotalPages(Math.ceil(newFilteredReports.length / 10));
    setReportsPerPage(10);
    setCurrentPage(1);
  }, [props.selectedRegion, props.selectedDimensions]);

  let getReportsList = () => {

    if (!props.company?.hubId || !props.company?.companyId) {
      return;
    }

    let url = `${Base_URL}/api/company/showCompanyMarketV2?hubId=${props.company?.hubId}&companyId=${props.company?.companyId}`;

    setLoading(true);
    axios
      .get(url, config)
      .then((res) => {
        let data = res?.data?.data;
        let newData = [...data].filter((report) => report.subscribed === "yes");
        setReports(data);
        setFilteredReports(data);
        setAllReports(data);
        setMyReports(newData);
        setCurrentPageReports(data.slice(0, 10));
        setTotalPages(Math.ceil(data.length / reportsPerPage));
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  let changeReportsPerPage = (event) => {
    setReportsPerPage(event.target.value);
    setTotalPages(Math.ceil(filteredReports.length / event.target.value));
    setCurrentPage(1);
    setCurrentPageReports(filteredReports.slice(0, event.target.value));
  };

  let changeCurrentPage = (type) => {
    let newPage;
    if (type === "previous") {
      if (currentPage <= 1) {
        newPage = 1;
        setCurrentPage(newPage);
      } else {
        newPage = currentPage - 1;
        setCurrentPage(newPage);
      }
    } else if (type === "next") {
      if (currentPage >= totalPages) {
        newPage = totalPages;
        setCurrentPage(newPage);
      } else {
        newPage = currentPage + 1;
        setCurrentPage(newPage);
      }
    }
    setCurrentPage(newPage);
    let newData = filteredReports.slice(
      reportsPerPage * (newPage - 1),
      reportsPerPage * newPage
    );
    setCurrentPageReports(newData);
  };

  let changeTab = (tab) => {
    setCurrentTab(tab);
    setCurrentPage(1);
    setReportsPerPage(10);
    if (tab === "my-reports") {
      let newData = [...reports].filter(
        (report) => report.subscribed === "yes"
      );
      setFilteredReports(newData);
      setCurrentPageReports(
        newData.slice(0, newData.length >= 10 ? 10 : newData.length)
      );
      setTotalPages(Math.ceil(newData.length / 10));
    } else if (tab === "all-reports") {
      setFilteredReports(reports);
      setCurrentPageReports(
        reports.slice(0, reports.length >= 10 ? 10 : reports.length)
      );
      setTotalPages(Math.ceil(reports.length / 10));
    }
  };

  let search = (event) => {
    let query = event.target.value.toLowerCase()?.trim();
    let data = [];

    if (currentTab === "my-reports") {
      data = [...reports].filter((report) => report.subscribed === "yes");
    } else if (currentTab === "all-reports") {
      data = [...reports];
    }

    if (query !== "") {
      data = [...data]?.filter((report) => {
        if (report?.title?.toLowerCase().includes(query)) {
          return true;
        } else {
          return false;
        }
      });
    } else {
      data = [...data];
    }

    setCurrentPage(1);
    setReportsPerPage(10);
    setTotalPages(Math.ceil(data.length / 10));
    setFilteredReports(data);
    setCurrentPageReports(data.slice(0, data.length >= 10 ? 10 : data.length));
  };

  let handleDebounceSearch = (event) => {
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => {
      search(event);
    }, 500);
  };

  return (
    <CompanyReportsContainer>
      {loading ? (
        <Loader />
      ) : (
        <>
          <ReportsHeader>
            <div>
              <button
                onClick={() => {
                  changeTab("all-reports");
                }}
                style={
                  currentTab === "all-reports" ? activeButtonStyle : buttonStyle
                }
              >
                {`All Reports (${allReports?.length ?? 0})`}
              </button>

              <button
                onClick={() => {
                  changeTab("my-reports");
                }}
                style={
                  currentTab === "my-reports" ? activeButtonStyle : buttonStyle
                }
              >
                {`My Reports (${myReports?.length ?? 0})`}
              </button>
            </div>
            <div className="search-report">
              <img src={SearchIcon} alt="" />
              <input
                placeholder="Search reports"
                onChange={handleDebounceSearch}
              />
            </div>
          </ReportsHeader>
          <ReportsList>
            {currentPageReports.length ? (
              currentPageReports?.map((report) => {
                return (
                  <HubsReportCard
                    style={{ marginBottom: "20px" }}
                    report_id={report.report_id}
                    title={report.title}
                    favourite={report.favourite}
                    subscribed={report?.subscribed}
                    report_url={
                      report?.subscribed === "yes" ? report?.report_url : null
                    }
                    report_sample_url={
                      report?.subscribed !== "yes" ? report?.report_url : null
                    }
                    slug={report.slug}
                    getReportData={getReportsList}
                    dimensions={report.dimensions}
                    hub_type={report?.hubName
                      .split(" ")
                      ?.map((str) => {
                        return (
                          str[0].toUpperCase() + str.slice(1).toLowerCase()
                        );
                      })
                      .join(" ")}
                    geography={report?.geography}
                    regions_covered={report?.regions_covered}
                    countries_covered={report?.countries_covered}
                    country={report?.country}
                    study_period={report.studyPeriod}
                    purchased_on={report.purchased_on}
                    renewal_on={report.renewal_on}
                    companyLogos={
                      report?.hasOwnProperty("mergeCP_Logos") &&
                      report.mergeCP_Logos
                        ? [report.mergeCP_Logos]
                        : report?.listOfCompanyLogos.map(
                            (logo) => logo?.image_url
                          )
                    }
                    subscribed_on={report?.subscribed_on}
                    published_year={report.published_year}
                    report_expiry={report?.report_expiry}
                    hubId={report.hub_id}
                    is_requested_catalogue_report={
                      report.is_requested_catalogue_report
                    }
                    ids={{
                      hubId: report.hub_id,
                      companyId: report.company_id,
                      reportId: report.report_id,
                    }}
                    category={props?.category}
                    company={props?.company}
                    hubTitle={props?.hubTitle}
                    hubSlug={props?.hubSlug}
                  />
                );
              })
            ) : (
              <p style={{ textAlign: "center", color: "gray", margin: "25px" }}>
                {
                  "You have not purchased a relevant report, Please check the ‘All Reports’ section."
                }
              </p>
            )}
          </ReportsList>
          <Pagination>
            <p>View reports per page</p>
            <select
              value={reportsPerPage}
              onChange={(event) => changeReportsPerPage(event)}
            >
              {paginationArr.map((n) => {
                return <option value={n}>{n}</option>;
              })}
            </select>

            <p>{`${currentPage} of ${totalPages || 1}`}</p>
            <button onClick={() => changeCurrentPage("previous")}>{"<"}</button>
            <button onClick={() => changeCurrentPage("next")}>{">"}</button>
          </Pagination>
        </>
      )}
    </CompanyReportsContainer>
  );
}

export default CompanyReports;
