import { useEffect, useState, useRef } from "react";
import { useParams, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useReactToPrint } from "react-to-print";
import styles from "./styles.module.css";
import downloadIcon from "../../../assets/download_icon.svg";
import downloadIconGray from "../../../assets/download_icon_gray.svg";
import printIcon from "../../../assets/print_icon.svg";
import Logo from "../../../assets/logo.png";
import Lock from "../../../assets/lock.svg";
import Loader from "../../Common/Loader";
import HubsHomeChatDialog from "../../GlobalComponents/ContactUs";
import axios from "axios";
import { useAuth } from "../../../context/authContext/Auth";
import "./styles.module.css";
import { useNavigate } from "react-router-dom";
import RenewDrawer from "../../Common/RenewDrawer";
import API from "../../../api/Api";
import {
  checkAndUpdateDownloadCredit,
  displayNoDownloadCredit,
} from "../../../utils/common";
import { setDownloadCredits } from "../../Redux/action";
import DownloadAccessChecker from "../../Common/DownloadOptionCheck/DownloadAccessChecker";
import LockBoxNew from "../../LockBox/LockBoxNew";
import { Modal, Button as ButtonX } from "antd";
function CompanyProfile(props) {
  const [loading, setLoading] = useState(true);
  const [pptLoading, setPptLoading] = useState(false);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [data, setData] = useState(null);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const contentToPrint = useRef();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { hubId, companyId, reportId, name, subscribed } = props;
  const [reqHandler, setReqHandler] = useState({
    requestStatus: "uninitialise",
    isButtonDisabled: false,
    requestMessage: "",
  });
  const { config, userInfo } = useAuth();
  const { Base_URL } = API;
  const [drawerOpen, setDrawerOpen] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { total_credit, remaining_credit, remaining_ondemand_credit } =
    useSelector((state) => state);
  const hasAccess = DownloadAccessChecker("report", reportId);

  useEffect(() => {
    getCompanyProfile();
  }, []);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancelmodal = () => {
    setIsModalOpen(false);
    if (reqHandler.requestStatus === "success") {
      window.location.reload();
    }
    setReqHandler((p) => {
      return { ...p, requestStatus: "uninitialise" };
    });

    setReqHandler((p) => {
      return { ...p, requestMessage: "" };
    });
  };

  const sendRequest = (event) => {
    event.preventDefault();
    setReqHandler((p) => {
      return { ...p, isButtonDisabled: true };
    });
    axios({
      url: `${Base_URL}/api/users/decrease-demand-credit`,
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        authorization: config.headers.authorization,
      },
      data: {
        report_id: reportId,
        message: reqHandler.requestMessage,
      },
    })
      .then((res) => {
        setReqHandler((p) => {
          return { ...p, requestStatus: "success" };
        });
      })
      .catch((error) => {
        setReqHandler((p) => {
          return { ...p, requestStatus: "failure" };
        });

        console.error(error);
      })
      .finally(() => {
        setReqHandler((p) => {
          return { ...p, isButtonDisabled: false };
        });
      });
  };
  let getCompanyProfile = async () => {
    setLoading(true);
    const bodyParameters = {
      hub_id: hubId,
      company_id: companyId,
      report_id: reportId,
    };

    let url = `${Base_URL}/api/company/viewCompanyProfile`;

    let res = await axios
      .post(url, bodyParameters, config)
      .then((res) => {
        let data = res?.data?.data;
        setData(data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const generatePpt = async () => {
    if (total_credit != null) {
      if (remaining_credit <= 0) {
        displayNoDownloadCredit(total_credit);
        return;
      }
    }

    setPptLoading(true);
    const bodyParameters = {
      file_title: `${props.companyName}-Company-Profile`,
      pptData: data?.pptData,
    };

    try {
      // start loading
      const response = await axios.post(
        `${Base_URL}/api/company/ppt-gen`,
        bodyParameters,
        config,
      );
      const url = response.data?.data?.url;
      handleDownload(url);
    } catch (error) {
      console.error(error);
    }
    setPptLoading(false);

    if (total_credit != null) {
      let credits = await checkAndUpdateDownloadCredit(config);

      dispatch(
        setDownloadCredits(credits.total_credit, credits.remaining_credit),
      );
    }
  };

  const handleCancel = () => navigate(-1);

  function handleDownload(url) {
    const link = document.createElement("a");
    link.href = url;
    link.download = true;
    link.click();
  }

  let generatePrint = useReactToPrint({
    content: () => contentToPrint.current,
    documentTitle: `${props.companyName}-Company Profile`,
    onAfterPrint: async () => {
      if (total_credit != null) {
        let credits = await checkAndUpdateDownloadCredit(config);
        dispatch(
          setDownloadCredits(credits.total_credit, credits.remaining_credit),
        );
      }
    },
  });

  let products = [];
  for (let i = 1; i < data?.products?.length; i++) {
    data?.products[i]?.map((prod) => {
      products.push(prod);
    });
  }

  let productsArr1 = products?.filter((prod, i) => i % 2 == 0);

  let productsArr2 = products?.filter((prod, i) => i % 2 != 0);

  let keyDevelopments = [];
  for (let i = 0; i < data?.Key_development?.length; i++) {
    data?.Key_development[i]?.map((dev) => {
      keyDevelopments.push(dev);
    });
  }

  return (
    <>
      {" "}
      <Modal
        title={name?.toUpperCase()}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancelmodal}
        width={"800px"}
        styles={{
          width: "800px",
        }}
        footer={null}
      >
        <div className="request-modal">
          {reqHandler.requestStatus === "success" && (
            <div>
              <h1
                style={{
                  color: "#1BB1F0",
                  fontSize: "32px",
                  fontWeight: "bold",
                }}
              >
                Thanks for the request!
              </h1>
              <p className="description">
                Our sales representative will be working on the same.
              </p>
              <p className="remaining-credits">
                Remaining Ondemand Credits :
                <span>{`${remaining_ondemand_credit}`}</span>
              </p>
            </div>
          )}
          {reqHandler.requestStatus === "uninitialise" && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <h3
                style={{
                  marginBottom: "20px",
                  color: "rgb(125, 125, 125)",
                  fontWeight: "500",
                  fontSize: "20px",
                }}
              >
                Viewing this report will cost 1 credit.
              </h3>

              <ButtonX
                onClick={sendRequest}
                disabled={reqHandler.isButtonDisabled}
              >
                Get Access
              </ButtonX>
            </div>
          )}

          {reqHandler.requestStatus === "failure" && (
            <div>
              <h1 style={{ color: "rgba(255, 0, 0, 0.7)" }}>
                Something Went Wrong!
              </h1>
              <p className="description">Please try in some time.</p>
              <p className="remaining-credits">Your credits do not decrease.</p>
            </div>
          )}
        </div>
      </Modal>
      <section className={styles.companyProfile}>
        <iframe
          id="ifmcontentstoprint"
          style={{ height: "0px", width: "0px", position: "absolute" }}
        ></iframe>
        <div className={styles.buttonsContainer}>
          {subscribed && hasAccess && (
            <button
              onClick={generatePpt}
              disabled={pptLoading}
              style={{
                filter: pptLoading ? "grayscale(1)" : "grayscale(0)",
                color: pptLoading
                  ? "rgb(174, 174, 174)"
                  : "rgba(0, 47, 117, 1)",
                borderColor: pptLoading
                  ? "rgb(174, 174, 174)"
                  : "rgba(0, 47, 117, 1)",
                cursor: pptLoading ? "progress" : "pointer",
              }}
            >
              <img src={pptLoading ? downloadIconGray : downloadIcon} />
              Download PPT
            </button>
          )}

          {total_credit === null &&
          remaining_credit === null &&
          hasAccess &&
          subscribed ? (
            <button
              onClick={() => {
                generatePrint();
              }}
            >
              <img src={printIcon} />
              Print
            </button>
          ) : null}

          {total_credit !== null &&
          remaining_credit > 0 &&
          hasAccess &&
          subscribed ? (
            <button
              onClick={() => {
                window.confirm(`Proceeding further will reduce Download credits by 1, either you print or cancel. 
            \nDo you want to Proceed ?`) && generatePrint();
              }}
            >
              <img src={printIcon} />
              Print
            </button>
          ) : null}
        </div>

        {loading ? (
          <Loader />
        ) : (
          <div
            ref={contentToPrint}
            id="printablecontent"
            className={styles.overviewContainer}
          >
            <div className={styles.globalOverview}>
              <h1>{props.companyName} - Global Overview</h1>
              <div className={styles.overview}>
                <div className={styles.firstColumn}>
                  <div
                    style={{
                      flex: "0 1 20%",
                      width: "100%",
                      // height: "150px",
                      padding: "10px",
                    }}
                  >
                    <img
                      src={data?.global_company?.logo_url}
                      alt="company-logo"
                    />
                  </div>
                  <div
                    style={{
                      flex: "0 1 80%",
                      background: "#E6E3FC",
                      padding: "10px",
                    }}
                  >
                    <h3>{data?.global_company?.analysis?.heading}</h3>
                    <ul className={!subscribed ? "blur" : ""}>
                      {data?.global_company?.analysis?.analysis?.length &&
                        data.global_company.analysis.analysis.map((point) => {
                          return <li>{point?.text} </li>;
                        })}
                    </ul>
                  </div>
                </div>
                <div className={styles.secondColumn}>
                  <div style={{ background: "#D7F6EB" }}>
                    <h2 className={!subscribed ? "blur" : ""}>
                      {data?.global_company?.revenue?.value +
                        " " +
                        data?.global_company?.revenue?.units[0]}
                    </h2>
                    <p>
                      Global Revenue in{" "}
                      {data?.global_company?.revenue?.currency} <br />
                      FY {data?.global_company?.revenue?.year} ( All Segments)
                    </p>
                  </div>
                  <div style={{ background: "#FFEDC7" }}>
                    <h2 className={!subscribed ? "blur" : ""}>
                      {data?.global_company?.employees?.value}
                    </h2>
                    <p>{data?.global_company?.employees?.text}</p>
                  </div>
                  <div style={{ background: "#A5E7EC" }}>
                    <h2 className={!subscribed ? "blur" : ""}>
                      {data?.global_company?.founded?.value}
                    </h2>
                    <p>{data?.global_company?.founded?.text}</p>
                  </div>
                  <div style={{ background: "#E6E3FC" }}>
                    <h2 className={!subscribed ? "blur" : ""}>
                      {data?.global_company?.headquarters?.value}
                    </h2>
                    <p>{data?.global_company?.headquarters?.text}</p>
                  </div>
                </div>
                <div className={styles.thirdColumn}>
                  <h3>
                    {data?.global_company?.core_business_segments?.heading}
                  </h3>
                  <ul className={!subscribed ? "blur" : ""}>
                    {data?.global_company?.core_business_segments
                      ?.business_segments.length &&
                      data?.global_company?.core_business_segments?.business_segments.map(
                        (segment) => {
                          return (
                            <li>
                              <h5>{segment?.heading}</h5>
                              {segment?.products.map((prod) => (
                                <p>{prod}</p>
                              ))}
                            </li>
                          );
                        },
                      )}
                  </ul>
                </div>
              </div>
            </div>

            <div className="page-break"></div>

            <div className={styles.marketOverview}>
              <h1>{props.companyName} - Market Overview</h1>
              <div className={styles.overview}>
                <div className={styles.fourthColumn}>
                  <h3>{data?.market_company?.core_products?.heading}</h3>
                  <ul>
                    {data?.market_company?.core_products?.products?.length &&
                      data?.market_company?.core_products?.products?.map(
                        (prod) => {
                          return (
                            <li className={!subscribed ? "blur" : ""}>
                              {prod}
                            </li>
                          );
                        },
                      )}
                  </ul>
                </div>
                {data?.pptData?.template === "companyProfile2" ? (
                  <div className={styles.fifthColumn}>
                    <div style={{ background: "#539DE4", flex: "0 1 20%" }}>
                      <h3
                        className={!subscribed ? "blur" : ""}
                        style={{
                          color: "#fff",
                          textTransform: "uppercase",
                        }}
                      >
                        {data?.market_company?.meta?.market_segment}
                      </h3>
                      <p
                        style={{
                          color: "#fff",
                          textTransform: "uppercase",
                        }}
                      >
                        MARKET SEGMENT
                      </p>
                    </div>

                    <div className={styles.highlightsBox}>
                      <h3>{"COMPANY HIGHLIGHTS"}</h3>
                      <ul className={!subscribed ? "blur" : ""}>
                        {data?.market_company?.meta?.company_highlights
                          ?.length &&
                          data?.market_company?.meta?.company_highlights?.map(
                            (highlight) => {
                              return <li>{highlight}</li>;
                            },
                          )}
                      </ul>
                    </div>
                  </div>
                ) : (
                  <div className={styles.fifthColumn}>
                    <div style={{ background: "#539DE4" }}>
                      <h3
                        className={!subscribed ? "blur" : ""}
                        style={{ color: "#fff", textTransform: "uppercase" }}
                      >
                        {data?.market_company?.middle_container[0]?.heading}
                      </h3>
                      <p style={{ color: "#fff", textTransform: "uppercase" }}>
                        {data?.market_company?.middle_container[0]?.text}
                      </p>
                    </div>

                    <div style={{ background: "#FFEDC7" }}>
                      <h2 className={!subscribed ? "blur" : ""}>
                        {data?.market_company?.middle_container[1]?.heading}
                      </h2>
                      <p>{data?.market_company?.middle_container[1]?.text}</p>
                    </div>
                    <div style={{ background: "#A5E7EC" }}>
                      <h2 className={!subscribed ? "blur" : ""}>
                        {data?.market_company?.middle_container[2]?.heading}
                      </h2>
                      <p>{data?.market_company?.middle_container[2]?.text}</p>
                    </div>
                    <div style={{ background: "#E6E3FC" }}>
                      <h2 className={!subscribed ? "blur" : ""}>
                        {data?.market_company?.middle_container[3]?.heading}
                      </h2>
                      <p>{data?.market_company?.middle_container[3]?.text}</p>
                    </div>
                  </div>
                )}

                <div className={styles.sixthColumn}>
                  <div
                    style={{
                      background: "#4BD0D9",
                      width: "100%",
                      flex: "0 1 20%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <h3
                      className={!subscribed ? "blur" : ""}
                      style={{ color: "#fff", textTransform: "uppercase" }}
                    >
                      {data?.market_company?.meta?.company_vertical}
                    </h3>
                    <p style={{ color: "#fff", textTransform: "uppercase" }}>
                      COMPANY VERTICAL
                    </p>
                  </div>
                  <div
                    style={{
                      flex: "0 1 78%",
                      background: "#E6E3FC",
                      padding: "10px",
                    }}
                  >
                    <h3>
                      {
                        data?.market_company?.meta?.other_key_information
                          ?.heading
                      }
                    </h3>
                    <ul className={!subscribed ? "blur" : ""}>
                      {data?.market_company?.meta?.other_key_information
                        ?.key_information?.length &&
                        data?.market_company?.meta?.other_key_information?.key_information?.map(
                          (key) => {
                            return <li>{key}</li>;
                          },
                        )}
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            {products?.length ? (
              <>
                <div className={productsArr1?.length ? "page-break" : ""}></div>
                <div className={styles.productService}>
                  <h1>{props.companyName} - Products & Services</h1>

                  <div>
                    {productsArr1?.length ? (
                      <table className={styles.tableFir}>
                        <tr style={{ backgroundColor: "#fff" }}>
                          <th>
                            {
                              data?.products?.[0]?.customHeadings
                                ?.firstLevelBrand
                            }
                          </th>
                          <th className={!subscribed ? "blur" : ""}>
                            {data?.products?.[0]?.customHeadings?.description}
                          </th>
                        </tr>
                        {productsArr1?.map((prod) => {
                          return (
                            <tr className={!subscribed ? "blur" : ""}>
                              <td>{prod?.first_level_brand}</td>
                              <td>{prod?.description?.text}</td>
                            </tr>
                          );
                        })}
                      </table>
                    ) : null}

                    {productsArr2?.length ? (
                      <table className={styles.tableSec}>
                        <tr style={{ backgroundColor: "#fff" }}>
                          <th>
                            {
                              data?.products?.[0]?.customHeadings
                                ?.firstLevelBrand
                            }
                          </th>
                          <th className={!subscribed ? "blur" : ""}>
                            {data?.products?.[0]?.customHeadings?.description}
                          </th>
                        </tr>
                        {productsArr2?.map((prod) => {
                          return (
                            <tr className={!subscribed ? "blur" : ""}>
                              <td>{prod?.first_level_brand}</td>
                              <td>{prod?.description?.text}</td>
                            </tr>
                          );
                        })}
                      </table>
                    ) : null}
                  </div>
                </div>
              </>
            ) : null}

            {keyDevelopments?.length ? (
              <>
                <div
                  className={keyDevelopments?.length ? "page-break" : ""}
                ></div>
                <div className={styles.keyDevelopment}>
                  <h1>{props.companyName} - Key Developments</h1>
                  <table className={styles.tableThir}>
                    <tr style={{ backgroundColor: "#fff" }}>
                      <th>Date</th>
                      <th>Type Of Key Development</th>
                      <th>Description</th>
                    </tr>
                    {keyDevelopments?.map((dev) => {
                      return (
                        <tr className={!subscribed ? "blur" : ""}>
                          <td>{dev?.date}</td>
                          <td>{dev?.type}</td>
                          <td>
                            {dev?.description?.map((desc) => {
                              return <p>{desc?.text}</p>;
                            })}
                          </td>
                        </tr>
                      );
                    })}
                  </table>
                </div>
              </>
            ) : null}
            {!subscribed && (
              <LockBoxNew
                componentName={"Company Profile"}
                sx={{ position: "relative", zIndex: "2", maxWidth: "400px" }}
                onclick={() =>
                  remaining_ondemand_credit > 0 ? showModal() : false
                }
              ></LockBoxNew>
            )}
          </div>
        )}
      </section>
    </>
  );
}

export default CompanyProfile;
