import { SYNAPSE_API } from "../../api/Api";
import { Box } from "@mui/material";
import AllGraphsDashboard from "../ArchiveReportDetails/AllGraphsDashboard";
import ExecSummary from "../ArchiveReportDetails/ExecSummary";
import Contents from "../ArchiveReportDetails/Toc";

const fetchData = (reportData) => {

  const body = {};
  if (reportData.userId && reportData.report_id) {
    body["userId"] = reportData.userId;
    body["reportId"] = reportData.report_id;
  }

  return [
    SYNAPSE_API.post(`/api/v1/flash/details/toc`, body),
    SYNAPSE_API.post(`/api/v1/flash/details/snippet`, body),
    SYNAPSE_API.post(
      `/api/v1/flash/details/dashboard-get-all-graphs-by-tags`,
      body
    ),
    SYNAPSE_API.post(`/api/v1/flash/details/graph-data`, body),
    SYNAPSE_API.post(`/api/v1/flash/details/exec-summary`, body),
  ];
};

export const getAllRequiredDetailsForReport = async (
  reportData,
  setTriggerApiCall,
  dispatch,
  setCurrentTab
) => {
  try {
    const promises = fetchData(reportData);
    const [toc, snippet, dashboard, _, execSummary] =
      await Promise.all(promises);

    const tabArray = [];

    if (toc.data.isDataAvailable)
      tabArray.push({
        name: "Contents",
        component: (
          <Contents
            data={toc.data.data.TOC}
            dispatch={dispatch}
            setCurrentTab={setCurrentTab}
          />
        ),
      });

    if (dashboard.data.isDataAvailable)
      tabArray.push({
        name: "Dashboard",
        component: (
          <AllGraphsDashboard
            data={dashboard.data.data}
            orderOfTags={dashboard.data.orderOfTags}
            isSubscribed={reportData.subscribed === "yes"}
            title={reportData.title}
            id={reportData.synapse_report_id}
            compName={"Dashboard"}
            setTriggerApiCall={setTriggerApiCall}
            dispatch={dispatch}
            setCurrentTab={setCurrentTab}
          />
        ),
      });

    if (execSummary.data.isDataAvailable)
      tabArray.push({
        name: "Executive Summary",
        component: (
          <ExecSummary
            data={execSummary.data.data.exec_summary}
            isSubscribed={reportData.subscribed === "yes"}
            title={reportData.title}
            id={reportData.synapse_report_id}
            setTriggerApiCall={setTriggerApiCall}
            snippetData={snippet.data.data.snippets}
            reportMeta={{
              title: reportData.title,
              publishedOn: reportData.published,
              forcastYear: reportData.forecast_year,
              websiteSlug: reportData.website_slug,
              countries: reportData.countries,
              regions: reportData.regions,
              reportExpiry: reportData.report_expiry,
              subscribedOn: reportData.subscribed_on,
            }}
          />
        ),
      });

    return tabArray;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const NoDataFoundForThis = () => {
  return (
    <Box
      sx={{
        minHeight: "500px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box component="center" sx={{ fontSize: "1rem" }}>
        No Data Found
      </Box>
    </Box>
  );
};
