import { useEffect, useState } from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import axios from "axios";
import { Box, Button } from "@mui/material";
import {
  Aside,
  Main,
} from "../../CompaniesComponents/CompanyHome/CompanyHome.styles";
import { Container } from "./HubCompanies.styles";
import API from "../../../api/Api";
import { useAuth } from "../../../context/authContext/Auth";
import CompanyReports from "../../CompaniesComponents/CompanyHome/CompanyReports";
import FilterSidebar from "../../Common/FilterSidebar";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { hubTitleWordsModify } from "../../../utils/common";

function HubsCompanyReports() {
  const [region, setRegion] = useState("");
  const [query, setQuery] = useState("");
  const [data, setData] = useState([]);
  const [company, setCompany] = useState({});
  const [regionData, setRegionData] = useState([]);
  const [dimensions, setDimensions] = useState({});
  const [selectedDimensions, setSelectedDimensions] = useState({});
  const [initData, setInitData] = useState([]);

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { config } = useAuth();
  const { state } = location;
  const { Base_URL } = API;

  const dotStyles = {
    display: "inline-block",
    height: "4px",
    width: "4px",
    background: "#000",
    borderRadius: "50%",
    margin: "0 10px",
  };

  const spanStyles = {
    color: "rgba(45, 45, 45, 1)",
    marginRight: "5px",
  };

  useEffect(() => {
    dispatch({
      type: "SET_NAVBAR_DATA",
      payload: {
        heading: null,
        paragraph: null,
        backButtonData: null,
        talkToUs: null,
        downloadBrochure: null,
        companiesPage: true,
        bannerHeight: "300px",
      },
    });
  }, []);

  useEffect(() => {
    const newDimensions = {};
    initData?.forEach((report) => {
      Object.keys(report.dimensions)?.forEach((dimension) => {
        if (!newDimensions[dimension]) {
          newDimensions[dimension] = new Set();
        }
        newDimensions[dimension].add(report.dimensions[dimension]);
      });
    });

    // Convert sets to arrays
    const dimensionsArr = {};
    Object.keys(newDimensions)?.forEach((dimension) => {
      // dimensionsArr[dimension] = Array.from(newDimensions[dimension]);
      //Sort the array
      dimensionsArr[dimension] = Array.from(newDimensions[dimension]).sort();
    });

    setDimensions(dimensionsArr);
  }, [initData]);

  useEffect(() => {
    setCompany(state?.company);
  }, [state]);

  useEffect(() => {
    getReportsList();
  }, [state?.hubId, state?.company?.companyId]);

  let getReportsList = () => {
    if (!state?.hubId || !state?.company?.companyId) {
      return;
    }
    let url = `${Base_URL}/api/company/showCompanyMarketV2?hubId=${state?.hubId}&companyId=${state?.company?.companyId}`;
    axios
      .get(url, config)
      .then((res) => {
        let data = res?.data?.data;
        const rData = [
          ...new Map(data?.map((m) => [m.dimensions.region, m])).values(),
        ];
        setRegionData(rData);
        setData(data);
        findDimensions(data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {});
  };

  let handleRegionChange = (child) => {
    const updatedData = {};
    for (const key in selectedDimensions) {
      updatedData[key] = "All";
    }

    setSelectedDimensions(updatedData);
    handleRegion(child);
  };

  let handleRegion = (key) => {
    setRegion(key);
    sessionStorage.setItem("region", JSON.stringify(key));
    if (key === "") {
      setInitData(data);
    } else {
      setInitData(data?.filter((item) => item.dimensions.region.includes(key)));
    }
  };

  let handleBcakNavigation = () => {
    return navigate(-1);
  };

  let findDimensions = (data) => {
    const newDimensions = {};
    data?.forEach((report) => {
      Object.keys(report.dimensions)?.forEach((dimension) => {
        if (!newDimensions[dimension]) {
          newDimensions[dimension] = new Set();
        }
        newDimensions[dimension].add(report.dimensions[dimension]);
      });
    });

    const dimensionsArr = {};
    Object.keys(newDimensions)?.forEach((dimension) => {
      dimensionsArr[dimension] = Array.from(newDimensions[dimension]);
    });

    setDimensions(dimensionsArr);
  };

  let resetAll = () => {
    const updatedData = {};
    for (const key in selectedDimensions) {
      updatedData[key] = "";
    }

    setSelectedDimensions({});
    handleRegion("");

    setQuery("");

    sessionStorage.removeItem("reportDimensions");
    sessionStorage.removeItem("region");
    sessionStorage.removeItem("reportCount");
  };

  let handleDimensionChange = (dimension, value) => {
    setSelectedDimensions((prevState) => ({
      ...prevState,
      [dimension]: value,
    }));

    const existingData =
      JSON.parse(sessionStorage.getItem("reportDimensions")) || {};
    const newData = { ...existingData, [dimension]: value };
    sessionStorage.setItem("reportDimensions", JSON.stringify(newData));
  };

  let hubName = state?.hubTitle
    ?.split(" ")
    ?.map((str) => {
      return str[0].toUpperCase() + str.slice(1);
    })
    .join(" ");

  return (
    <div
      style={{
        marginTop: "-140px",
        position: "relative",
        zIndex: "100",
      }}
    >
      <Container
        style={{
          marginTop: "0px",
          marginBottom: "50px",
          flexDirection: "column",
        }}
      >
        <p
          style={{
            fontSize: "16px",
            fontWeight: "400",
            lineHeight: "26px",
            letterSpacing: "0em",
            textAlign: "left",
            color: "rgba(90, 90, 90, 1)",
            marginBottom: "10px",
            marginLeft: "-10px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <div style={{ cursor: "pointer" }} onClick={handleBcakNavigation}>
            <span>
              <ArrowBackIosNewIcon
                sx={{ color: "#03b2cb", fontSize: "12px" }}
              />{" "}
            </span>
          </div>
          <Box>
            <Button
              onClick={() => {
                navigate(-2);
              }}
              sx={{
                textTransform: "capitalize",
                color: "#5a5a5a",
              }}
            >
              {"My Hubs"}
            </Button>{" "}
            /
            <Link
              state={{
                id: state?.hubId,
              }}
              to={"/hubs/" + state?.hubSlug}
            >
              <Button
                sx={{
                  textTransform: "capitalize",
                  color: "#5a5a5a",
                }}
              >
                {hubTitleWordsModify(hubName)}
              </Button>
            </Link>
            /
            <Button
              onClick={() => {
                navigate(`/hubs/${state?.hubSlug}`, {
                  state: {
                    id: state?.hubId,
                    isRedirectFromCompanyReports: true,
                  },
                });
              }}
              sx={{
                textTransform: "capitalize",
                color: "#5a5a5a",
              }}
            >
              Companies Covered
            </Button>
          </Box>
        </p>

        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "flex-start",
          }}
        >
          <div
            style={{
              width: "48px",
              height: "48px",
              marginLeft: "50px",
            }}
          >
            <img
              style={{ objectFit: "cover", width: "100%" }}
              src={company?.logo}
            />
          </div>
          <div>
            <h2
              style={{
                color: "rgba(25, 25, 25, 1)",
                fontSize: "24px",
                fontWeight: "800",
                lineHeight: "36px",
                letterSpacing: "0em",
                textAlign: "left",
                marginLeft: "14px",
                marginBottom: "4px",
              }}
            >
              {company?.name}
            </h2>
            <p
              style={{
                color: "rgba(90, 90, 90, 1)",
                display: "flex",
                alignItems: "center",
                marginLeft: "14px",
              }}
            >
              <span style={spanStyles}>Est.:</span>

              {company?.founded}
              <span style={dotStyles}></span>
              <span style={spanStyles}>Country: </span>
              {company?.country}

              <span style={dotStyles}></span>
              <span style={spanStyles}>Headquarters: </span>
              {company?.headquarters}
              <span style={dotStyles}></span>
              <span style={spanStyles}>Annual Revenue: </span>
              {`$ ${company?.annual_revenue} ${company?.units}`}
              <span style={dotStyles}></span>
              <span style={spanStyles}>Employees: </span>
              {company?.employees}
            </p>
          </div>
        </div>
      </Container>

      <Container>
        <Aside style={{ marginTop: "25px" }}>
          <FilterSidebar
            data={dimensions}
            handleDimensionChange={handleDimensionChange}
            resetAll={resetAll}
            handleRegionChange={handleRegionChange}
            region={region}
            regionData={regionData}
          />
        </Aside>

        <Main>
          <CompanyReports
            company={state?.company}
            category={state?.category}
            hubTitle={state?.hubTitle}
            hubSlug={state?.hubSlug}
            selectedDimensions={selectedDimensions}
            selectedRegion={region}
          />
        </Main>
      </Container>
    </div>
  );
}

export default HubsCompanyReports;
