import React, { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Companies from "./pages/companies";
import Indicators from "./pages/indicators";
import Reports from "./pages/Reports";
import Login from "./pages/login";
import Profile from "./pages/profile";
import Hubs from "./pages/hub/";
import HubItem from "./pages/hub-item/";
import HubReportData from "./pages/hub-report-data";
import Layout from "./components/GlobalComponents/Layout";
import CompanyMarket from "./components/CompaniesComponents/CompanyMarket";
import CompanyShowPdf from "./components/CompaniesComponents/CompanyShowPdf";
import PrivateRoute from "./components/GlobalComponents/PrivateRoute";
import DashboardOverview from "./components/HubsComponents/DashboardOverview";
import DashboardSegmentation from "./components/HubsComponents/DashboardSegmentation";
import DashboardMarketShare from "./components/HubsComponents/DashboardMarketShare";
import CompanyProfile from "./components/CompaniesComponents/CompanyProfile";
import ForgotPassword from "./pages/forgot-password";
import NotFoundPage from "./components/GlobalComponents/NotFoundPage";
import CustomData from "./pages/customData";
import UsersProfile from "./pages/users";
import Favorites from "./components/UserProfile/Favorites";
import UserManagement from "./components/UserProfile/UserManagement";
import NoData from "./components/Common/NoData";
import ChangePassword from "./components/UserProfile/ChangePassword";
import Register from "./pages/register";
import InvitationRegister from "./pages/invitationRegister";
import NewPassword from "./pages/new-password";
import UserAccessHOC from "./components/HOC/UserAccess";
import ReportDetails from "./pages/ReportDetails";
import PurchaseHistory from "./components/UserProfile/PurchaseHistory";
import CompanyReport from "./components/CompaniesComponents/CompanyReport";
import PowerBIEmbed from "./components/Reports/PowerBIEmbed";


import HubsCompanyReports from "./components/HubsComponents/HubsCompanies/HubsCompanyReports";
import Credits from "./components/UserProfile/Credits";
import ArchiveReportDetails from "./components/ArchiveReportDetails";
import AnswerEngine from "./components/AnswerEngine/AnswerEngine";
import Company from "./pages/company";
import FlashReportDetails from "./components/FlashReportDetails";

function App() {
  useEffect(() => {
    const handlePrint = (event) => {
      if (event.ctrlKey && event.key === 'p') {
        event.preventDefault(); // Prevent default action (printing)
        // alert('add the text here if needed for the alert');
      }
    };

    window.addEventListener('keydown', handlePrint);

    return () => {
      window.removeEventListener('keydown', handlePrint);
    };
  }, []);

  const location = useLocation()
  useEffect(() => {
    const arr = ['/', '/change-password', '/register'];
    const elements = document.getElementsByClassName('intercom-lightweight-app-launcher');
    
    for (let i = 0; i < elements.length; i++) {
      elements[i].style.display = arr.includes(location.pathname) ? 'none' : 'block';
    }
  }, [location]);


  return (
    <>
      <Routes>
        <Route element={<UserAccessHOC />}>
          <Route path="/" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/register-for-invitation" element={<InvitationRegister/>}/>
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/new-password/:key" element={<NewPassword />} />
        </Route>
        <Route
          element={
            <PrivateRoute>
              <Layout />
            </PrivateRoute>
          }
        >
          <Route path="/powerbi" element={<PowerBIEmbed />} />
          <Route path="/myra-ai" element={<AnswerEngine from="global" />} />

          <Route path="/users" element={<UsersProfile />} />
          {/* <Route path="/reports" element={<Reports />} /> */}
          <Route path="/reports" >
            <Route index element={<Reports />} />
            <Route path="flash/:slug" element={<FlashReportDetails />} />
            <Route path=":slug" element={<ReportDetails />} />
            <Route path="reports-library/:slug" element={<ArchiveReportDetails />} />
          </Route>
          {/* <Route path="/bookmarks" element={<Favorites/>} /> */}
          <Route path="/user-management" element={<UserManagement />} />
          <Route path="/credits" element={<Credits />} />
          <Route path="/purchase-history" >
            <Route index element={<PurchaseHistory />} />
            <Route path=":slug" element={<ReportDetails />} />
            <Route path="reports-library/:slug" element={<ArchiveReportDetails />} />
          </Route>
          <Route path="/bookmarks" >
            <Route index element={<Favorites />} />
            <Route path="reports-library/:slug" element={<ArchiveReportDetails />} />
            <Route path=":slug" element={<ReportDetails />} />
            <Route path="hubs/:id">
              <Route index element={<HubItem />} />
              <Route path=":slug" element={<ReportDetails />} />
              <Route path="companies">
                <Route index element={<Companies />} />
                <Route path=":slug" >
                  <Route index element={<HubsCompanyReports />} />
                  <Route path=":slug">
                    <Route index element={<CompanyReport />} />
                  </Route>
                </Route>
                <Route path="market">

                  <Route index element={<CompanyMarket />} />
                  <Route path="profile" element={<CompanyProfile />} />
                  <Route path=":id" element={<CompanyShowPdf />} />
                </Route>
              </Route>
            </Route>
          </Route>
          <Route path="/change-password" element={<ChangePassword />} />

          <Route path="/hubs">
            <Route index element={<Hubs />} />
            <Route path="companies/report/:slug" element={<CompanyReport />} />
            <Route path=":id">
              <Route index element={<HubItem />} />
              {/* <Route path=":report" element={<HubReportData />} /> */}
              <Route path=":slug" element={<ReportDetails />} />
              <Route path="companies">
                <Route index element={<Companies />} />
                <Route path=":slug" >
                  <Route index element={<HubsCompanyReports />} />
                  <Route path=":slug">
                    <Route index element={<CompanyReport />} />
                  </Route>
                </Route>
                <Route path="market">

                  <Route index element={<CompanyMarket />} />
                  <Route path="profile" element={<CompanyProfile />} />
                  <Route path=":id" element={<CompanyShowPdf />} />
                </Route>
              </Route>
            </Route>
          </Route>

          <Route path="/companies">
            {/* <Route index element={<Companies />} /> */}
            <Route index element={<Company />} />
            <Route path=":slug" element={<CompanyReport />} />
            <Route path="market">
              <Route index element={<CompanyMarket />} />
              <Route path="profile" element={<CompanyProfile />} />
              <Route path=":id" element={<CompanyShowPdf />} />
            </Route>
          </Route>
          <Route path="/indicators" element={<Indicators />} />
          <Route path="/custom-data" element={<CustomData />} />
        </Route>
        <Route path="/profile" element={<Profile />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </>
  );
}

export default App;
