import ReportLayout from "./ReportLayout";
import Snippet from "../../components/ReportSection/Snippet";
import { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { Dashboard } from "./Dashboard";
import VisualiseCustom from "../../components/Visualise/VisualiseCustom";
import { useDispatch, useSelector } from "react-redux";
import { useAuth } from "../../context/authContext/Auth";
import HubsReportDSMultiSelect from "../../components/HubsComponents/HubsReportDSMultiSelect";
import ReportDetailsTab from "./ReportDetailsTab";
import DatasheetDetailsTab from "../../components/Reports/DatasheetDetailsTab";
import Loader from "../../components/Common/Loader";
import { Alert, AlertTitle, Snackbar, Box } from "@mui/material";
import { SYNAPSE_API } from "../../api/Api";
import { downloadPdfFromUrlAndMemoized, MyRABtn } from "../../utils/common";
import { doNotShowMyRaForTheseHubReports } from "./reportsDetails.util";
import AnswerEngine from "../../components/AnswerEngine/AnswerEngine";

function ReportDetails() {
  const dispatch = useDispatch();

  const selectedReportDetail = useSelector(
    (state) => state.selectedReportDetail
  );
  const { currentTab, setCurrentTab } = useAuth();

  const { id: hub, slug } = useParams();

  const [reportId, setReportId] = useState("");
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [loading, setLoading] = useState(false);
  const [reportData, setReportData] = useState(null);
  const [flashReport, setFlashReport] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [reportTabsToRender, setReportTabsToRender] = useState([]);

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  useEffect(() => {
    const moveToTop = setTimeout(() => {
      window.scroll(0, 470);
    }, 0);
    return () => {
      clearTimeout(moveToTop);
    };
  }, [currentTab]);

  const fetchReport = useCallback(async () => {
    setLoading(true);
    try {
      const data = await fetchReportDataBySlug(slug, setSnackbarOpen);

      if (data === null) {
        setReportTabsToRender([]);
        setCurrentTab(null);
        return;
      }
      const tabsRequired = [];
      let requiredPdfUrlToDownload = undefined;

      // if hub_id not available its a flash report
      if (!data.hub_id) {
        if (data?.subscribed === "yes") {
          if (data?.custom?.report_pdfs && !data?.custom?.report_excel) {
            requiredPdfUrlToDownload = data?.custom?.report_pdfs[0]?.url;
            tabsRequired.push("Report");
          } else if (data?.custom?.report_pdfs && data?.custom?.report_excel) {
            requiredPdfUrlToDownload = data?.custom?.report_pdfs[0]?.url;
            tabsRequired.push("Report", "Data Sheet");
            setReportTabsToRender(["Report", "Data Sheet"]);
          } else if (!data?.custom?.report_pdfs && data?.custom?.report_excel) {
            tabsRequired.push("Data Sheet");
          } else {
            tabsRequired.push("Snippet", "Report");
          }
        } else {
          requiredPdfUrlToDownload = data?.report_sample_url;
          tabsRequired.push(
            "Snippet",
            `${data.report_sample_url ? "Sample" : ""} Report `
          );
        }

        setFlashReport(true);
      } else {
        if (data.custom?.report_pdfs && data.custom?.report_excel) {
          requiredPdfUrlToDownload = data?.custom?.report_pdfs[0]?.url;
          tabsRequired.push("Report", "Data Sheet");
        } else if (data.custom?.report_pdfs && !data.custom?.report_excel) {
          requiredPdfUrlToDownload = data?.custom?.report_pdfs[0]?.url;
          tabsRequired.push("Report");
        } else if (data.custom?.report_excel && !data.custom?.report_pdfs) {
          tabsRequired.push("Data Sheet");
        } else {
          requiredPdfUrlToDownload =
            data?.subscribed === "yes"
              ? data?.report_url
              : data?.report_sample_url;
          tabsRequired.push(
            "Dashboard",
            "Snippet",
            `Report ${data?.subscribed !== "no" ? "" : "Sample"}`,
            "Data Sheet"
          );
          // SOME HUB REPORTS WILL NOT HAVE MY RA BUTTON
          if (
            doNotShowMyRaForTheseHubReports.includes(data.report_id) === false
          ) {
            tabsRequired.push(<MyRABtn />);
          }
        }
      }

      downloadPdfFromUrlAndMemoized(requiredPdfUrlToDownload).then((pdf) => {
        dispatch({
          type: "SET_INITIAL_REPORT_BUFFER",
          payload: {
            url: requiredPdfUrlToDownload,
            data: pdf,
          },
        });
      });

      setReportTabsToRender(tabsRequired);
      setReportId(data?.report_id);
      setIsSubscribed(data?.subscribed === "yes" ? true : false);
      localStorage.setItem("newReportId", data?.report_id);
      setReportData(data);
      dispatch({
        type: "SET_CURRENT_REPORT_DETAIL",
        payload: data,
      });
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }, [slug]);

  useEffect(() => {
    fetchReport();
  }, [slug]);

  const DoesCurrentTabExist = ({ toRender }) => {
    if (reportTabsToRender.length === 0) return <InvalidReportUi />;
    if (toRender) {
      setCurrentTab(0);
      return toRender;
    }
    return <InvalidReportUi />;
  };
  const createTabBasedOnReport = (currentTab) => {
    if(!reportData) return;
    if (flashReport) {
      const flashTab = {
        0: (
          <Snippet
            isSubscribed={selectedReportDetail.subscribed}
            {...{ slug, fetchReport }}
          />
        ),
        1: (
          <ReportDetailsTab
            subscribed={selectedReportDetail?.subscribed}
            sampleUrl={selectedReportDetail?.report_sample_url}
            blurUrl={selectedReportDetail?.blur_pdf_url}
            reportUrl={selectedReportDetail?.report_url}
            id={selectedReportDetail?.id}
            title={selectedReportDetail?.title}
            reportPdf={selectedReportDetail?.custom?.report_pdfs}
            isFlash={
              selectedReportDetail?.custom?.report_pdfs &&
              selectedReportDetail?.subscribed === "yes"
                ? true
                : false
            }
          />
        ),
        2: (
          <DatasheetDetailsTab
            reportExcel={selectedReportDetail?.custom?.report_excel}
          />
        ),
      };
      if (selectedReportDetail?.custom?.report_pdfs) {
        flashTab[0] = (
          <ReportDetailsTab
            subscribed={selectedReportDetail?.subscribed}
            sampleUrl={selectedReportDetail?.report_sample_url}
            blurUrl={selectedReportDetail?.blur_pdf_url}
            reportUrl={selectedReportDetail?.report_url}
            id={selectedReportDetail?.id}
            title={selectedReportDetail?.title}
            reportPdf={selectedReportDetail?.custom?.report_pdfs}
            isFlash={
              selectedReportDetail?.custom?.report_pdfs &&
              selectedReportDetail?.subscribed === "yes"
                ? true
                : false
            }
          />
        );
        flashTab[1] = (
          <DatasheetDetailsTab
            reportExcel={selectedReportDetail?.custom?.report_excel}
          />
        );
      }
      if (
        selectedReportDetail?.subscribed === "yes" &&
        !selectedReportDetail?.custom?.report_pdfs &&
        selectedReportDetail?.custom?.report_excel
      ) {
        flashTab[0] = (
          <DatasheetDetailsTab
            reportExcel={selectedReportDetail?.custom?.report_excel}
          />
        );
        delete flashTab[2];
      }

      return (
        flashTab[currentTab] ?? <DoesCurrentTabExist toRender={flashTab[0]} />
      );
    }

    let tabFactory = {
      0: <Dashboard isSubscribed={isSubscribed} reportId={reportId} />,
      1: (
        <Snippet
          isSubscribed={isSubscribed}
          slug={slug}
          fetchReport={fetchReport}
        />
      ),
      2: (
        <ReportDetailsTab
          subscribed={reportData?.subscribed}
          sampleUrl={reportData?.report_sample_url}
          blurUrl={reportData?.blur_pdf_url}
          reportUrl={reportData?.report_url}
          id={reportData?.id}
          title={reportData?.title}
          reportPdf={reportData?.custom?.report_pdfs}
        />
      ),
      3: (
        <HubsReportDSMultiSelect
          sheetType={"dataSheet"}
          isSubscribed={isSubscribed}
          fromDashboard={true}
        />
      ),
    };

    // SOME HUB REPORTS WILL NOT HAVE MY RA BUTTON
    if (
      doNotShowMyRaForTheseHubReports.includes(reportData?.report_id) === false
    ) {
      tabFactory["4"] = (
        <AnswerEngine
          id={reportData?.id}
          hubId={reportData?.hub_id}
          fetchReport={fetchReport}
          subscribed={reportData?.subscribed === "yes"}
          title={reportData?.title}
          uploadId={reportData.upload_id}
          from={"hub_report"}
        />
      );
    }

    // WHEN WE UPLOAD CUSTOM FILE FOR hub report WE WILL REMOVE ALL OTHER TABS

    if (reportData?.custom?.report_excel && reportData?.custom?.report_pdfs) {
      tabFactory[0] = (
        <ReportDetailsTab
          subscribed={reportData?.subscribed}
          sampleUrl={reportData?.report_sample_url}
          blurUrl={reportData?.blur_pdf_url}
          reportUrl={reportData?.report_url}
          id={reportData?.id}
          title={reportData?.title}
          reportPdf={reportData?.custom?.report_pdfs}
        />
      );
      tabFactory[1] = (
        <DatasheetDetailsTab reportExcel={reportData?.custom?.report_excel} />
      );
    } else if (
      reportData?.custom?.report_pdfs &&
      !reportData?.custom?.report_excel
    ) {
      tabFactory[0] = (
        <ReportDetailsTab
          subscribed={reportData?.subscribed}
          sampleUrl={reportData?.report_sample_url}
          blurUrl={reportData?.blur_pdf_url}
          reportUrl={reportData?.report_url}
          id={reportData?.id}
          title={reportData?.title}
          reportPdf={reportData?.custom?.report_pdfs}
        />
      );
    } else if (
      reportData?.custom?.report_excel &&
      !reportData?.custom?.report_pdfs
    ) {
      tabFactory[0] = (
        <DatasheetDetailsTab reportExcel={reportData?.custom?.report_excel} />
      );
    }

    return (
      tabFactory[currentTab] ?? <DoesCurrentTabExist toRender={tabFactory[0]} />
    );
  };

  return (
    <ReportLayout
      {...{
        setCurrentTab,
        currentTab,
        hub,
        reportTabsToRender,
        fetchReport,
        reportData,
      }}
    >
      {loading ? (
        <Loader />
      ) : (
        <main>
          {createTabBasedOnReport(currentTab)}
          <Snackbar
            open={snackbarOpen}
            autoHideDuration={3000}
            onClose={handleSnackbarClose}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
          >
            <Alert onClose={handleSnackbarClose} severity="error">
              <AlertTitle>Error</AlertTitle>
              Report not found — <strong>check it out!</strong>
            </Alert>
          </Snackbar>
        </main>
      )}
    </ReportLayout>
  );
}

export default ReportDetails;

const fetchReportDataBySlug = async (slug, setSnackbarOpen) => {
  try {
    const reportData = await SYNAPSE_API.get(
      `/api/hubs/report-by-slug/${slug}`
    );
    return reportData?.data?.data;
  } catch (error) {
    setSnackbarOpen(true);
    console.error(error);
    return null;
  }
};

const InvalidReportUi = () => {
  return (
    <Box
      component="center"
      sx={{
        height: "30vh",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <h1>This Report is invalid or some error has occurred</h1>
    </Box>
  );
};
