import { Button } from "@mui/material";
import { notification } from "antd";
import axios from "axios";
import API, { SYNAPSE_API } from "../api/Api";

function formatDate(stringDate) {
  const date = new Date(stringDate);
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const day = date.getUTCDate();
  const monthIndex = date.getUTCMonth();
  const year = date.getUTCFullYear();
  return `${day} ${months[monthIndex]}, ${year}`;
}

function formatMonth(stringDate) {
  const date = new Date(stringDate);
  const year = date.getUTCFullYear();
  return `${year}`;
}

function scrollToSection({ id, modifyTop = 0, options = {} }) {
  if (!id) return;
  const sectionElement = document.getElementById(id);
  if (sectionElement) {
    const rect = sectionElement.getBoundingClientRect();
    window.scrollTo({
      top: rect.top - modifyTop + window.scrollY,
      behavior: "smooth",
      ...options,
    });
  }
}

function hubTitleWordsModify(title) {
  if (!title) return "";

  const [gccReg, cepReg, ledReg, mlccReg] = [
    /\bGcc\b/gi,
    /\bCep\b/gi,
    /\bLed\b/gi,
    /\bMlcc\b/gi,
  ];

  if (title.match(gccReg)) {
    return title.replace(gccReg, "GCC");
  } else if (title.match(cepReg)) {
    return title.replace(cepReg, "CEP");
  } else if (title.match(ledReg)) {
    return title.replace(ledReg, "LED");
  } else if (title.match(mlccReg)) {
    return title.replace(mlccReg, "MLCC");
  } else {
    return title;
  }
}

async function checkAndUpdateDownloadCredit(config) {
  const { Base_URL } = API;
  let credits = {
    remaining_credit: 0,
    total_credit: 0,
  };

  await axios
    .patch(`${Base_URL}/api/users/decrease-credit`, {}, config)
    .then((res) => {
      let { remaining_credit, total_credit } = res.data?.data?.credit;
      credits.remaining_credit = remaining_credit;
      credits.total_credit = total_credit;
      let args = {
        message: "Remaining Credits.",
        description: `You have ${remaining_credit} download credit left out of ${total_credit}.`,
        duration: 2,
        placement: "topRight",
      };
      notification.warning(args);
    })
    .catch((err) => {
      let args = {
        message: "Oops.",
        description: `Something went wrong while updating remaining credit.`,
        duration: 2,
        placement: "topRight",
      };
      notification.error(args);
    });

  return credits;
}

function displayNoDownloadCredit(total_credit) {
  let args = {
    message: "No Credits Left.",
    description: `You have ${"0"} download credit left out of ${total_credit}.`,
    duration: 2,
    placement: "topRight",
  };
  notification.error(args);
}

function getRandomNumber(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

const reportPdfMemoised = {};

const getPdfUrlUnSigned = (pdfUrl) => {
  const pdfUrlWithoutQuery = pdfUrl?.split("?")[0];
  if (!pdfUrlWithoutQuery) return pdfUrl;
  return pdfUrlWithoutQuery;
};

const downloadPdfFromUrlAndMemoized = async (pdfUrl) => {
  if (!pdfUrl) {
    console.error("No PDF URL provided");
    return;
  }
  const pdfUrlWithoutQuery = getPdfUrlUnSigned(pdfUrl);

  if (reportPdfMemoised[pdfUrlWithoutQuery]) {
    return reportPdfMemoised[pdfUrlWithoutQuery];
  }

  try {
    const response = await fetch(pdfUrl);
    if (!response.ok) {
      console.error("Failed to download PDF:", response.statusText);
      throw new Error(response.statusText);
    }
    const blob = await response.blob();
    const array8 = URL.createObjectURL(blob);

    reportPdfMemoised[pdfUrlWithoutQuery] = array8;

    console.log("PDF DOWNLOAD COMPLETE");

    // CLEAR OBJECT URL AFTER 1 HOUR TO FREE UP MEMORY
    setTimeout(() => {
      URL.revokeObjectURL(array8);
      delete reportPdfMemoised[pdfUrlWithoutQuery];
    }, 3600 * 1000);

    return array8;
  } catch (error) {
    console.error("Failed to download or process PDF:", error);
    throw error;
  }
};

function MyRABtn() {
  return (
    <Button
      variant="contained"
      sx={{
        color: "rgb(169, 77, 25)",
        backgroundColor: "rgb(255, 237, 199)",
        padding: "6px 18px",
        textTransform: "none",
        borderRadius: "7px",
        height: "38px",
        marginBottom: "6px",
        "&:hover": {
          backgroundColor: "rgb(255, 237, 199)",
          color: "rgb(169, 77, 25)",
        },
      }}
      z
    >
      myRA AI
    </Button>
  );
}

function setCookie(key, value, hours = 24) {

  const requiredDomain = {
    "synapse.mordorlabs.com": ".mordorlabs.com",
    "synapse.mordorintelligence.com": ".mordorintelligence.com",
    "v2-synapse.mordorintelligence.com": ".mordorintelligence.com",
    localhost: "",
  };

  let date = new Date();
  date.setTime(date.getTime() + hours * 60 * 60 * 1000); // Set the expiration time in milliseconds
  let expires = "expires=" + date.toUTCString();
  const domainReq = requiredDomain[window.location.hostname];
  document.cookie =
    key + "=" + value + ";expires=" + expires + `;domain=${domainReq};path=/`;
}

async function authenticateProdgain() {
  return;
  SYNAPSE_API.post("/api/v1/myra/authenticate-prodgain", {})
  .then((res) => {
    const authToken = res.data.authToken;
    const email = res.data.email;
    setCookie("authToken", authToken, 24);
    setCookie("email", email, 24);
  })
  .catch((error) => {
    console.error("Error during authentication:", error);
  });
}

export {
  formatDate,
  scrollToSection,
  hubTitleWordsModify,
  checkAndUpdateDownloadCredit,
  displayNoDownloadCredit,
  getRandomNumber,
  downloadPdfFromUrlAndMemoized,
  getPdfUrlUnSigned,
  formatMonth,
  MyRABtn,
  authenticateProdgain,
  setCookie,
};
